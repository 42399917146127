import React,{ Component } from 'react'
import ReactDOM from 'react-dom'    //下拉刷新组件依赖react-dom，所以需要将其引进来
import { Image,message } from 'antd';
import { PullToRefresh, ListView } from 'antd-mobile';
import Api from '../../api';
import './App3.css';
class ListContainer extends Component {
  constructor(props) {
    super(props);
    const dataSource = new ListView.DataSource({  //这个dataSource有cloneWithRows方法
      rowHasChanged: (row1, row2) => row1 !== row2,
    });    
    this.pageNo = 0 //定义分页信息
    this.state = {
      category:props.category,
      category2:props.category2,
      area: props.area,
      dataListType: props.dataListType,
      location_x: props.location_x,
      location_y: props.location_y,
      dataSource,
      refreshing: true,
      isLoading: true,
      height: document.documentElement.clientHeight,
      useBodyScroll: true,
      hasMore: true
    };
  }
  componentWillReceiveProps(){
  }
  componentDidUpdate() {
    if(this.props.category!=this.state.category||this.props.category2!=this.state.category2||this.props.area!=this.state.area||this.props.location_x!=this.state.location_x||this.props.location_y!=this.state.location_y){
      this.setState({category: this.props.category});
      this.setState({category2: this.props.category2});
      this.setState({area: this.props.area});
      this.setState({location_x: this.props.location_x});
      this.setState({location_y: this.props.location_y});
      this.pageNo = 0;
      const hei = this.state.height - ReactDOM.findDOMNode(this.lv).offsetTop;
      this.setState({ refreshing: true, isLoading: true });
      // console.log(this.state.dataSource);
      // message.destroy();
      // message.loading('加载数据中请稍等');
      setTimeout(async() => {
        this.rData = await this.genData();
        this.setState({
          dataSource: this.state.dataSource.cloneWithRows(this.rData),
          height: hei,
          refreshing: false,
          isLoading: false,
        });
      }, 600);
    }
    // if (this.state.useBodyScroll) {
    //   document.body.style.overflow = 'auto';
    // } else {
    //   document.body.style.overflow = 'hidden';
    // }
  }

  async componentDidMount() {
    const hei = this.state.height - ReactDOM.findDOMNode(this.lv).offsetTop;
    // this.rData = (await this.genData()).sceneryinfo;
    setTimeout(async() => {
      this.rData = await this.genData();
      this.setState({
        dataSource: this.state.dataSource.cloneWithRows(this.rData),
        height: hei,
        refreshing: false,
        isLoading: false,
      });
    }, 600);

  }
   genData=async(e)=>{  //请求数据的方法
    this.pageNo++;     //每次下拉的时候pageNo++(页数)
    let url = '';
    if(this.state.dataListType === 'tuijian'){  //推荐店铺
      url = Api.url+'/shop/recommend?wx_token='+ localStorage.getItem('wx_token')+'&appId='+Api.appId+'&category_id='+this.state.category+'&pagesize=5&page='+this.pageNo;
    }else if (this.state.dataListType === 'fujin'){ //附近店铺
      url = Api.url+'/shop?wx_token='+localStorage.getItem('wx_token')+'&appId='+Api.appId+'&category_id='+this.state.category2+'&area='+this.state.area+'&location_x='+this.state.location_x+'&location_y='+this.state.location_y+'&pagesize=20&page='+this.pageNo;
    }
    let thisObj  = this;
    return fetch(url, 
      {
        method: 'GET',
        // mode: 'cors',
        headers: {
            'content-type': 'application/json',
        },
      })
      .then(response => response.json())
      .then(function(result) {
          if(result){
            if((result.data.data).length<20){
              thisObj.setState({hasMore: false})
            }
            return result.data.data
          }else{
            thisObj.setState({ hasMore: false })
          }
      })
  }
  onRefresh = () => {
    this.pageNo = 0;
    const hei = this.state.height - ReactDOM.findDOMNode(this.lv).offsetTop;
    this.setState({ refreshing: true, isLoading: true });
    setTimeout(async() => {
      this.rData = await this.genData();
      this.setState({
        dataSource: this.state.dataSource.cloneWithRows(this.rData),
        height: hei,
        refreshing: false,
        isLoading: false,
      });
    }, 600);
  };

  onEndReached = async (event) => {
    if (this.state.isLoading && !this.state.hasMore) {
      return;
    }   
    this.setState({ isLoading: true });
    this.rData = [...this.rData, ...await this.genData()];  
    this.setState({
      dataSource: this.state.dataSource.cloneWithRows(this.rData),
      isLoading: false,
    });
  };

  render() {
    const row = (rowData, sectionID, rowID) => {
      return (
        <div key={rowID}
          className="shopLists"
          style={{
            backgroundColor: 'white',
            borderBottom: '1px solid #eee'
          }}
        >
          <div></div>
          <div style={{ display: '-webkit-box', display: 'flex', padding: '15px',position: 'relative' }}>
            <div style={{display: 'flex', alignItems: 'center', marginLeft: '1vh'}}>
              <Image style={{ height: '70px', width: '70px', marginRight: '15px' }} src={rowData.img_url} alt="" />
            </div>
            <div style={{ display: 'inline-block', textAlign: 'left', color: '#999' ,flex: 1}}>
              <div style={{ marginBottom: '3px', color: '#000', fontSize: '15px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', maxWidth: '250px' }}>{rowData.name}</div>
              <div className="major">{rowData.major}</div> 
              <div style={{ fontSize: '11px',display:'flex',alignItems:'center' }}><img style={{marginRight:'5px', marginBottom:'3px'}} src="position.png" width='9' height='11' alt=""/><span className="addressName">{rowData.address}</span></div>
            </div>
            <div style={{display: 'flex', alignItems: 'center', marginLeft: '2vh', width:'25px'}}>
              {
                rowData.red_pocket === 1 || rowData.red_pocket === '1'?
                <img src="hongbao2.png" alt="" width='25'/>:null
              }
            </div>
            <wx-open-launch-weapp class="launch-btn" username="gh_71a136efa86d" path={"pages/index/index?store_id="+rowData.id}
                style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
              >
              <script type="text/wxtag-template">
                <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', opacity: 0 }} />       
              </script> 
            </wx-open-launch-weapp>
          </div>
        </div>
      );
    };
    return (
      <div>
        <ListView
          key={this.state.useBodyScroll ? '0' : '1'}
          ref={el => this.lv = el}
          dataSource={this.state.dataSource}
          renderFooter={    
            () => (
                  <div style={{ padding: 30, textAlign: 'center' }}>
                    {
                    this.state.hasMore === false? '没有更多了':
                      this.state.isLoading ? '加载中...' : '下拉加载更多'}
                  </div>
                )
          }
          renderRow={row}
          useBodyScroll={this.state.useBodyScroll}
          style={this.state.useBodyScroll ? {} : {
            height: this.state.height,
            border: '1px solid #ddd',
            margin: '5px 0',
          }}
          pullToRefresh={<PullToRefresh
            refreshing={this.state.refreshing}
            onRefresh={this.onRefresh}
          />}
          scrollRenderAheadDistance={500}
          onEndReached={this.onEndReached}
          onEndReachedThreshold={10}
          pageSize={20}    //每次下拉之后显示的数据条数
        />
      </div>
    );
  }
}

export default ListContainer