import React, { useState, useEffect } from 'react';
import './App.css';
import { message } from 'antd';
import { List, Carousel, Modal,Tabs } from 'antd-mobile';
import App1 from './pages/appPages/App1';
import App2 from './pages/appPages/App2';
import App3 from './pages/appPages/App3';
import Api from './api';
import wx from 'weixin-js-sdk';
import {  RouteComponentProps } from 'react-router-dom';
import { StickyContainer, Sticky } from 'react-sticky';
let wins:any = window;
let BMap = wins.BMap;
let is_recommend_str:any = '推荐';
let iscategory_str = true;
interface InitProps { }
type MainProps = InitProps & RouteComponentProps;
const App: React.FC<MainProps> = (props: MainProps) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [modalVisible2, setModalVisible2] = useState(false);
  const [category, setCategory] = useState<string>('-1'); // 推荐--->分类ID
  const [category2, setCategory2] = useState<string>('-1'); // 附近--->分类ID
  const [area, setArea] = useState<string>('-1'); // 区域ID
  const [is_recommend,setIs_recommend] = useState<any>('推荐');
  const [pid,setPid] = useState<string>('');  // pid
  const [iscategory, setIsCategory] = useState<boolean>(true); // 分类/附近
  const [categoryData, setCategoryData] = useState<any>([]); // 分类弹窗内容
  const [categoryData2, setCategoryData2] = useState<any>([]); // 附近弹窗内容-->省/市
  const [categoryData3, setCategoryData3] = useState<any>([]); // 附近弹窗内容-->区
  const [carouselData, setCarouselData] = useState<any[]>([]);  // 轮播图的图片
  const [showCarousel, setShowCarousel] = useState<boolean>(true);
  const [isVip, setIsVip] = useState<boolean>(true);   // 是否 VIP
  const [hasShop, setHasShop] = useState<boolean>(true);   // 是否 有店铺
  const [tabListName1, setTabListName1] = useState<string>('分类');
  const [tabListName2, setTabListName2] = useState<string>('附近');
  const [tabListName3, setTabListName3] = useState<string>('分类');
  const [cityName, setCityName] = useState<string>('');   // 附近 --->定位的城市名
  const [imgHeight, setImgHeight] = useState<any>(176);
  const [is_has_shop, setIs_has_shop] = useState<any>(0); // 0--->无店铺：C端   1--->有店铺:b端
  const [location_x, setLocation_x] = useState<string>('');
  const [location_y, setLocation_y] = useState<string>('');
  // const { TabPane } = Tabs;
  document.body.style.overflow = 'hidden';
  let pid_str = '';
  window.onscroll = function () { // 监测页面滚动 隐藏轮播图
    let scrollTop = document.documentElement.scrollTop|| window.pageYOffset || document.body.scrollTop;
    if (scrollTop > 225) {
      if(showCarousel){
        setShowCarousel(false);
      }
    }else{
      if(!showCarousel){
        setShowCarousel(true);
      }
    }
  }
  useEffect(() => { // 初始调用的一些方法
    document.title = '商圈';
    if(!localStorage.getItem('wx_token')||!localStorage.getItem('isNew')){
      const getQueryVariable = (variable: any)=>{        
        let query = window.location.search.substring(1);
        let vars = query.split("&");
        for (let i in vars) {
          let pair = vars[i].split("=");
          if(pair[0] == variable){return pair[1];}
        }
        // return(false);
      }
      if (getQueryVariable('wx_token')){
        let a = getQueryVariable('wx_token') + '';
        localStorage.setItem('wx_token',a);
        localStorage.setItem('isNew','1');// 这个是强行判断是不是最新的wx_token的
        getCarousel();
        // 本地运行在微信公众号开发工具打开
        // 例如 http://localhost:3001/?wx_token=2RYkANda1BZbsfEu
        setTimeout(()=>{
          if(localStorage.getItem('wx_token')&&localStorage.getItem('isNew')){
            window.location.replace(Api.url2);  // 这里第一次登录时，wx.config会执行报错因为url冲突，不知道怎么改所以暂时改成跳转了
          }
        },1000)
      }else{
        getLoginData(); //  获取登录token
      }
    }else{
      getCarousel();
      getUserInformation();
      getWXconfig();
      getUserInformationPublic();
    }
  }, []);
  const tabs = [
    { title: '推荐' },
    { title: '附近' },
  ];
  function renderTabBar(props: any) { // 下滑 固定顶部区域
    return (<Sticky>
      {({ style }) => <div style={{ ...style, zIndex: 1, top: '8vh'}}>
      
        <Tabs.DefaultTabBar {...props} onTabClick={(e)=>{is_recommend_str= e.title;setIs_recommend(e.title);}}/>
        
        </div>}
    </Sticky>);
  }
  const getWXconfig = async () =>{  // 微信config的配置    
    return fetch(Api.url+'/wechat/sign?url='+Api.url2, 
      {
        method: 'GET',
        headers: {
            'content-type': 'application/json',
        }
      })
      .then(response => response.json())
      .then(function(res) {
        wx.config(res.data);
        const hide = message.loading({
          content: '正在获取您的当前位置',
          style: {
            marginTop: '20vh',
          },
        });
        wx.ready(function(){
          wx.getLocation({
            type: 'wgs84', // 默认为wgs84的gps坐标，如果要返回直接给openLocation用的火星坐标，可传入'gcj02'
            success: function (res) {
              var latitude = res.latitude; // 纬度，浮点数，范围为90 ~ -90
              var longitude = res.longitude; // 经度，浮点数，范围为180 ~ -180。
              var speed = res.speed; // 速度，以米/每秒计
              var accuracy = res.accuracy; // 位置精度
              setLocation_x(longitude)
              setLocation_y(latitude)
              wins = window;
              BMap = wins.BMap;
              var myGeo = new BMap.Geocoder();
              myGeo.getLocation(
                new BMap.Point(longitude, latitude),
                function (result: any) {
                  hide();
                  let province1 = result.addressComponents.province;
                  let city1 = result.addressComponents.city;
                  let district1 = result.addressComponents.district;
                  if(province1 === city1){
                    setCityName(province1+district1);
                  }else{
                    setCityName(province1+city1);
                  }
                }
              );
            }
          });
        })
    })
  }
  const getCategoryData = async () => {// 推荐店铺--->分类 /附近店铺--->附近/分类
    message.destroy();
    const hide =  message.loading({
      content: '加载中请稍等',
      style: {
        marginTop: '20vh',
      },
    });
    let url = '';
    if(is_recommend_str === '推荐'){
      url = Api.url+'/shop/category/all?wx_token='+ localStorage.getItem('wx_token')+'&appId='+Api.appId+'&is_recommend='+'1'
    }else if(is_recommend_str==='附近'){
      if(iscategory_str){
        url= Api.url+'/shop/category/all?wx_token='+ localStorage.getItem('wx_token')+'&appId='+Api.appId+'&is_recommend='+'2'+'&area='+area
      }else{        
        url= Api.url+'/area/shop?wx_token='+ localStorage.getItem('wx_token')+'&appId='+Api.appId+'&categories='+category2+'&pid='+pid_str
      }
    }
    return fetch(url, 
      {
        method: 'GET',
        headers: {
            'content-type': 'application/json',
        },
      })
      .then(response => response.json())
      .then(function(result) {
        if(result){
          hide();
          if(is_recommend_str === '推荐'){
            setCategoryData(result.data);
            setTimeout(()=>{
              setModalVisible(true);
            },600)
            return
          }else if(is_recommend_str==='附近'){
            if(iscategory_str){
              setCategoryData(result.data);
              setTimeout(()=>{
                setModalVisible(true);
              },600)
              return
            }
            if(pid_str!==''){
              setCategoryData3(result.data);
              setTimeout(()=>{
                setModalVisible(true);
              },600)
              return
            }else{
              setCategoryData2(result.data);
              setTimeout(()=>{
                setModalVisible(true);
              },600)
              return
            }
          }
        }
      })
  };
  const getLoginData = async () => {  // 获取用户登录信息
    return fetch(Api.url+'/wechat/official_url?url='+ Api.url2, 
      {
        method: 'GET',
        headers: {
            'content-type': 'application/json',
        },
      })
      .then(response => response.json())
      .then(function(result) {
        if(result){
          if(result.code===0&&result.msg==='success'){
            window.location.replace(result.data);
            return true;
          }else{
            message.error({
              content: result.msg,
              style: {
                marginTop: '20vh',
              },
            });
            return false;
          }
        }else{
          message.error({
            content: '获取您的信息失败，请重试',
            style: {
              marginTop: '20vh',
            },
          });
          return false;
        }
      })
  };
  const getCarousel = async () => {   //轮播图
    return fetch(Api.url+'/carousel?wx_token='+ localStorage.getItem('wx_token') +'&appId='+Api.appId+'&shop_id=1&is_all=1',{
      method: 'GET',
      headers: {
        'content-type': 'application/json',
      },
    })
    .then(response => response.json())
    .then(function(result) {
      if(result){
        if(result.code===0){
          setCarouselData(result.data)
        }else{
          message.error({
            content: result.msg,
            style: {
              marginTop: '20vh',
            },
          });
          return false;
        }
      }else{
        message.error({
          content: '获取轮播图失败，请重试',
          style: {
            marginTop: '20vh',
          },
        });
        return false;
      }
    })
  }
  const getUserInformation = async () =>{ // 获取个人信息--->判断是不是VIP，有无店铺
    return fetch(Api.url+'/user/show?wx_token='+ localStorage.getItem('wx_token') +'&appId='+Api.appId,{
      method: 'GET',
      headers: {
        'content-type': 'application/json',
      },
    })
    .then(response => response.json())
    .then(function(result) {
      if(result){
        if(result.code===0&&result.msg==='success'){
          if(result.data.shop!==null){
            setHasShop(true);
            if(result.data.shop.vip_info!==null){
              setIsVip(true);
            }else{
              setIsVip(false);
            }
          }else{
            setHasShop(false);
          }
          return true;
        }else{
          message.error({
            content: result.msg,
            style: {
              marginTop: '20vh',
            },
          });
          return false;
        }
      }else{
        message.error({
          content: '获取您的信息失败，请重试',
          style: {
            marginTop: '20vh',
          },
        });
        return false;
      }
    })
  }
  const getUserInformationPublic = async () =>{  // 用户信息公共接口
    return fetch(Api.url+'/user/?wx_token='+ localStorage.getItem('wx_token') +'&appId='+Api.appId,{
      method: 'GET',
      headers: {
        'content-type': 'application/json',
      },
    })
    .then(response => response.json())
    .then(function(result) {
      if(result){
        if(result.code===0&&result.msg==='success'){
          if(is_has_shop===1){
            setIs_has_shop(1);
          }else if(is_has_shop===0){
            setIs_has_shop(0);
          }
          return true;
        }else{
          message.error({
            content: result.msg,
            style: {
              marginTop: '20vh',
            },
          });
          return false;
        }
      }else{
        message.error({
          content: '获取您的信息失败，请重试',
          style: {
            marginTop: '20vh',
          },
        });
        return false;
      }
    })
  }
  return (
    <div className="App">
        <StickyContainer>
      <div className='AppBody' >
      <Sticky>
        {({ style }) => <div style={{ ...style, zIndex: 1 }}>
            <div className='topDiv'>  {/*头部搜索框区域 */}
              <div className='seachDiv flex'>
                <img src='seach.png' width='15' height='15' />
                <span style={{marginLeft:'13px',fontSize:'13px',color:'#666'}}>{'请输入主营产品或店铺名称'}</span></div>
              <App1/>
            </div>
          </div>}
        </Sticky>
         {   // 轮播图
          // showCarousel?
          carouselData.length>0? 
          <Carousel
            autoplay
            infinite
            // beforeChange={(from, to) => console.log(`slide from ${from} to ${to}`)}
            // afterChange={index => console.log('slide to', index)}
          >
          {
            carouselData.map((val:any) => {
              return(
                <a key={val.id} style={{ display: 'inline-block', width: '100%', height: 164 }}>
                  <img
                    src={val.img_url}
                    alt=""
                    style={{ width: '100%',height: 164, verticalAlign: 'top' }}
                    onLoad={() => {
                      // fire window resize event to change height
                      window.dispatchEvent(new Event('resize'));
                      setImgHeight('auto');
                    }}
                  />
                </a>
              )
            })
          } 
        </Carousel>:null}             
        <StickyContainer>
        <div className={showCarousel?'isShowCarousel':'noShowCarousel'} style={{backgroundColor: '#fff'}}>
          <Tabs tabs={tabs}
            onTabClick={(e)=>{is_recommend_str=e.title;setIs_recommend(e.title);}}
            renderTabBar={renderTabBar}
          >
          <div style={{ display: 'flex', flexDirection:'column', backgroundColor: '#fff' }}>
            {showCarousel?
            <div className={'tabsTitle'}>
                  <div onClick={async()=>{
                    const aaa = async()=>{
                      iscategory_str = true;
                      setIsCategory(true);
                      setCategoryData([]);
                      setCategoryData2([]);
                      setCategoryData3([]);
                    }
                    aaa().then(()=>{
                      getCategoryData()
                    })
                    }}>{tabListName1}<img src={'down.png'} width='12' height='8' style={{marginLeft:'5px'}}/></div>
                {!hasShop||!isVip?
                  <div className={'wantRecommendBtn'} onClick={()=>{if(!hasShop||!isVip){setModalVisible2(true)}}}><span>{'我也要上推荐榜'}</span></div>
                  :null
                }
              </div>:null
              }
              <div>
                <App3 dataListType={'tuijian'} category={category}/>
              </div>
          </div>
            {/* <TabPane tab="推荐店铺" key="1">
            </TabPane> */}
          <div style={{ display: 'flex', flexDirection:'column', backgroundColor: '#fff' }}>
            {showCarousel?
              <div className={'tabsTitle'}>
                <div onClick={async()=>{
                    const aaa = async()=>{
                      iscategory_str = false;
                      setIsCategory(false);
                    }
                    aaa().then(()=>{
                      getCategoryData()
                    })
                  }}>{tabListName2 }<span style={{fontSize:'0.7rem',color:'#999',fontWeight:'normal'}}>{'(当前位置:'}{cityName}{')'}</span><img src={'down.png'} width='12' height='8' style={{marginLeft:'5px'}}/></div>
                <div onClick={async()=>{
                  const aaa = async()=>{
                    iscategory_str = true;
                    setIsCategory(true);
                  }
                  aaa().then(()=>{
                    getCategoryData()
                  })
                  }}>{tabListName3}<img src={'down.png'} width='12' height='8' style={{marginLeft:'5px'}}/></div>
              </div>
            :null}
            <div>
              <App3 dataListType={'fujin'} category2={category2} area={area} location_x={location_x} location_y={location_y}/>
            </div>
          </div>
            {/* <TabPane tab="附近店铺" key="2">              
            </TabPane> */}
          </Tabs>
        </div>
        </StickyContainer>
      </div>
      </StickyContainer>
      {
        is_has_shop===1?<div className={'hongbaoDiv'} onClick={()=>{props.history.push('/myWallet')}}>
          <img src="hongbao3.jpg" alt="" width='60'/>
        </div>:null
      }
      <Modal
        popup
        animationType="slide-up"
        className={'popupModal'}
        visible={modalVisible}
        onClose={()=>{setModalVisible(false);setCategoryData([]);setCategoryData2([]);setCategoryData3([]);}}
      >
        {
          is_recommend==='推荐'?
          <div className="modalTitle1">{tabListName1}<img src={'up.png'} width='12' height='8' style={{marginLeft: '5px'}}/></div>
          :is_recommend==='附近'?
          <div style={{height: '44px',display: 'flex', alignItems: 'center',justifyContent:'space-between'}}>
            <span style={iscategory?{padding:'15px'}:{padding:'15px',color:'#cc351e'}}
              onClick={()=>{setIsCategory(false);iscategory_str=false;getCategoryData();}}
            >
              <span style={{fontWeight:'bold'}}>{tabListName2}</span>
              <span style={{fontSize:'0.7rem',color:'#999',fontWeight:'normal'}}>{'(当前位置:'}{cityName}{')'}</span>
              {iscategory?<img src={'down.png'} width='12' height='8' style={{marginLeft: '5px'}}/>:<img src={'up.png'} width='12' height='8' style={{marginLeft: '5px'}}/>}
            </span>
            <span style={iscategory?{padding:'15px',color:'#cc351e'}:{padding:'15px'}}
              onClick={()=>{setIsCategory(true);iscategory_str= true;getCategoryData();}}>
              <span style={{fontWeight:'bold'}}>{tabListName3}</span>
              {iscategory?<img src={'up.png'} width='12' height='8' style={{marginLeft: '5px'}}/>:<img src={'down.png'} width='12' height='8' style={{marginLeft: '5px'}}/>}
            </span>
          </div>
          :null
        }
        {
          iscategory?
          <List className="popup-list"> {/* 分类 */}
            {categoryData.map((i:any) => (
              <List.Item  onClick={()=>{
                setCategoryData2([]);
                setCategoryData3([]);
                if(is_recommend_str === '推荐'){
                  setCategory(i.id);
                  setTabListName1(i.name);
                }else if(is_recommend_str === '附近'){
                  setCategory2(i.id);
                  setTabListName3(i.name);
                }
                setModalVisible(false)}} key={i.id}>
                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                  <span>{i.name}</span><span>{i.shop_count+'家'}</span>
                </div>
                </List.Item>
            ))}
            <List.Item>
            </List.Item>
          </List>
          :<div style={{display:'flex'}}><List className="popup-list" style={{width: '50%',borderRight:'1px solid #eee'}}> {/* 附近   */}
            {categoryData2.map((i:any) => (
              <List.Item onClick={()=>{
                setCategoryData([]);
                  if(i.id===0||i.id==='0'){
                    setArea(i.id);
                    setTabListName2(i.name);                    
                    setCategoryData3([]);
                    setModalVisible(false);
                  }else{
                    const aaa = async()=>{
                      pid_str = i.id;
                      setPid(i.id);
                      setTabListName2(i.name);
                    }
                    aaa().then(()=>{
                      getCategoryData()
                    })                    
                  }
                }} key={i.id}>
                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                  <span>{i.name}</span><span>{i.num+'家'}</span>
                </div>
                </List.Item>
            ))}
            <List.Item>
            </List.Item>
          </List>
          {
            categoryData3.length>0?
            <List className="popup-list" style={{width: '50%'}}> {/* 附近--->分类   */}
              {categoryData3.map((i:any) => (
                <List.Item  onClick={()=>{setArea(i.id);setModalVisible(false);}} key={i.id}>
                  <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    <span>{i.name}</span><span>{i.num+'家'}</span>
                  </div>
                  </List.Item>
              ))}
              <List.Item>
              </List.Item>
            </List>:null
          }
          </div>
        }
      </Modal>
      <Modal title={'系统提示'} className="modalStyle2" visible={modalVisible2} onClose={()=>setModalVisible2(false)}>
        <div className="modal2Body">
          {!hasShop?('您还未入驻店铺，请先入驻!!!')
            :!isVip?('您还不是VIP，请先开通VIP!!!')
            :null
          }
          </div>
          <div className="modalStyle2Btn">
            <div className="modalStyle2BtnDiv" style={{borderRight: '1px solid #ddd'}} onClick={()=>{setModalVisible2(false)}}>{'取消'}</div>
            {!hasShop?<div className="modalStyle2BtnDiv" style={{color: '#cc351e',position:'relative'}}>{'立即入驻'}<App2 isVip={isVip} hasShop={hasShop}/></div>
              :!isVip?<div className="modalStyle2BtnDiv" style={{color: '#cc351e',position:'relative'}}>{'开通VIP'}<App2 isVip={isVip} hasShop={hasShop}/></div>
              :null
            }
            
          </div>
        {/* <Button style={{width:'80%'}} type="primary" danger>
          {!hasShop?('我要开店')
            :!isVip?('开通VIP')
            :null
          }
            <App2 isVip={isVip} hasShop={hasShop}/>
          </Button> */}
      </Modal>
    </div>
  );
}
export default App;