import React from 'react';
import {HashRouter, Route, Switch,BrowserRouter} from 'react-router-dom';
import App from './App';
import MyWallet from './pages/myWallet/myWallet';
import Extract from './pages/extract/extract';
import JumpApplets from './pages/appPages/JumpApplets';
import Contact_service from './pages/Contact_service/Contact_service';
const BasicRoute = () => (
    <BrowserRouter>
        <Switch>
            <Route exact path="/" component={App}/>
            <Route exact path="/myWallet" component={MyWallet}/>
            <Route exact path="/extract" component={Extract}/>
            <Route exact path="/jumpApplets" component={JumpApplets}/>
            <Route exact path="/contact_service" component={Contact_service}/>
        </Switch>
    </BrowserRouter>
);

export default BasicRoute;