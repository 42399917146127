import React, { useState, useEffect } from 'react';
import { InputNumber, Input, Button, Form , message,Modal} from 'antd';
import { RouteComponentProps } from 'react-router-dom';
import './extract.css';
import Api from '../../api';

interface InitProps { }
type MainProps = InitProps & RouteComponentProps;
const Extract: React.FC<MainProps> = (props: MainProps) => {
  const [form] = Form.useForm();
  const [modalVisible, setModalVisible] = useState(false);
  const [myMoney, setMyMoney] = useState<string>('');
  const [inputValue, setInputValue] = useState<string>('');
  const [mobile, setMobile] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const [daojishi, setDaojishi] = useState<number>(60);
  const [timeShow, setTimeShow] = useState(false);
  const [code_sms, setCode_sms] = useState<string>('');
  useEffect(() => {
    document.title = '提现';
    const hide = message.loading({
      content: '加载中请稍等',
      style: {
        marginTop: '20vh',
      },
    });
    getWallet();
    getUser();
    hide();
  }, []);
  const getWallet = async()=>{  // 获取钱包
    message.destroy();
    return fetch('https://dmp.machmall.net/api/v1/user/wallet?wx_token='+ localStorage.getItem('wx_token') +'&appId='+Api.appId, 
      {
        method: 'GET',
        headers: {
            'content-type': 'application/json',
        },
      })
      .then(response => response.json())
      .then(function(result) {
          if(result){
            if(result.code===0&&result.msg==='success'){
              setMyMoney(result.data);
              return true;
            }else{
              message.error({
                content: result.msg,
                style: {
                  marginTop: '20vh',
                },
              });
              return false;
            }       
          }else{
            message.error({
              content: '获取钱包信息失败，请重试',
              style: {
                marginTop: '20vh',
              },
            });
          }
      })
  }
  const wallet_withdraw = async()=>{  // 提现
    message.destroy();
    if(!/^1([358][0-9]|4[579]|66|7[0135678]|9[89])[0-9]{8}$/.test(mobile)){
      message.error({
        content: '请先绑定手机号',
        style: {
          marginTop: '20vh',
        },
      });
      setModalVisible(true);
      return false;
    }
    if(!form.getFieldValue('extractMoney')){
      message.error({
        content: '提现金额不能为空',
        style: {
          marginTop: '20vh',
        },
      });
      return false;
    }
    if(form.getFieldValue('extractMoney')>myMoney){
      message.error({
        content: '提现金额不能超过钱包余额',
        style: {
          marginTop: '20vh',
        },
      });
      return false;
    }
    if(parseFloat(form.getFieldValue('extractMoney'))<0.3){
      message.error({
        content: '提现金额不能低于0.3元',
        style: {
          marginTop: '20vh',
        },
      });
      return false;
    }    
    const hide =  message.loading({
      content: '提现中请稍等',
      style: {
        marginTop: '20vh',
      },
    });
    return fetch('https://dmp.machmall.net/api/v1/user/wallet_withdraw', 
      {
        method: 'POST',
        mode: 'cors',
        headers: {
            'content-type': 'application/json',
        },
        body: JSON.stringify({
          wx_token: localStorage.getItem('wx_token'),
          appId: Api.appId,
          total_amount: form.getFieldValue('extractMoney')
        })
      })
      .then(response => response.json())
      .then(function(result) {
          if(result){
            if(result.code===0&&result.msg==='success'){
              hide();
              message.loading({
                content: '提现成功，请查收您的账户',
                style: {
                  marginTop: '20vh',
                },
              });
              getWallet();
              setMyMoney('0');
              return true;
            }else{
              message.error({
                content: result.msg,
                style: {
                  marginTop: '20vh',
                },
              });
              return false;
            }
          }else{
            message.error({
              content: '提现失败，请重试',
              style: {
                marginTop: '20vh',
              },
            });
            return false;
          }
      })
  }
  const getUser = async()=>{  //获取用户信息
    message.destroy();
    return fetch('https://dmp.machmall.net/api/v1/user?wx_token='+ localStorage.getItem('wx_token') +'&appId='+Api.appId, 
      {
        method: 'GET',
        headers: {
            'content-type': 'application/json',
        },
      })
      .then(response => response.json())
      .then(function(result) {
          if(result){
            if(result.code===0&&result.msg==='success'){
              setMobile(result.data.mobile);
              return true;
            }else{
              message.error({
                content: result.msg,
                style: {
                  marginTop: '20vh',
                },
              });
              return false;
            }       
          }else{
            message.error({
              content: '获取信息失败，请重试',
              style: {
                marginTop: '20vh',
              },
            });
          }
      })
  }
  const send_sms = async()=>{  //发送验证码
    message.destroy();
    return fetch('https://dmp.machmall.net/api/v1/user/send_sms?wx_token='+ localStorage.getItem('wx_token') +'&appId='+Api.appId+'&mobile='+phone, 
      {
        method: 'GET',
        headers: {
            'content-type': 'application/json',
        },
      })
      .then(response => response.json())
      .then(function(result) {
          if(result){
            if(result.code===0&&result.msg==='success'){
              return true;
            }else{
              message.error({
                content: result.msg,
                style: {
                  marginTop: '20vh',
                },
              });
              return false;
            }
          }else{
            message.error({
              content: '获取失败，请重试',
              style: {
                marginTop: '20vh',
              },
            });
          }
      })
  }
  const bind_mobile = async()=>{  //验证验证码并绑定手机号
    message.destroy();
    return fetch('https://dmp.machmall.net/api/v1/user/bind_mobile', 
      {
        method: 'POST',
        mode: 'cors',
        headers: {
            'content-type': 'application/json',
        },
        body: JSON.stringify({
          wx_token: localStorage.getItem('wx_token'),
          appId: Api.appId,
          mobile:phone,
          code:code_sms
        })
      })
      .then(response => response.json())
      .then(function(result) {
          if(result){
            if(result.code===0&&result.msg==='success'){
              message.success({
                content: '绑定手机号成功，您可以继续提现啦',
                style: {
                  marginTop: '20vh',
                },
              });
              window.location.reload();
              return true;
            }else{
              message.error({
                content: result.msg,
                style: {
                  marginTop: '20vh',
                },
              });
              return false;
            }
          }else{
            message.error({
              content: '获取失败，请重试',
              style: {
                marginTop: '20vh',
              },
            });
          }
      })
  }
  return (
    <div style={{background: '#eee',width: '100%', height: '100%',borderTop:'1px solid #ddd'}}>
      <div style={{padding: '2vh', fontSize: '1rem'}}>{'提现至微信钱包'}</div>
      <div style={{background: '#fff', padding: '2vh 2vh 0 2vh'}}>
        <div><span style={{fontSize: '1rem'}}>{'提现金额'}</span><span style={{fontSize: '0.7rem', color: '#999'}}>{'（提现金额不低于0.30元，每天不能超过10次）'}</span></div>
          <div style={{display: 'flex',alignItems: 'center',marginTop:'5px',borderBottom: '1px solid #ddd'}}>
            <span style={{fontSize: 20}}>{'￥'}</span>
            <Form form={form} layout="inline" style={{flex: 1}}>
                <Form.Item name={'extractMoney'} style={{flex: 1}}>
                    <InputNumber style={{border: 'none', width: '100%',fontSize: 25}} onChange={(value: string)=>{setInputValue(value)}}/>
                </Form.Item>
            </Form>
        </div>
        {
          myMoney?
          parseFloat(inputValue)>parseFloat(myMoney)?<div style={{margin:'2vh',fontSize:15,color:'#cc351e'}}>{'金额不能超过钱包余额'}</div>
          :(parseFloat(myMoney)<0.3||parseFloat(inputValue)<0.3)?
          <div style={{margin:'2vh',fontSize:15,color:'#cc351e'}}>{'根据微信规则，提现金额最低不能少于0.3元'}</div>          
          :<div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', color:'#999'}}>
            <span>{'机器猫钱包余额￥'}<span>{myMoney}</span></span>
            <div style={{padding: '2vh', color: '#cc351e'}} onClick={()=>{setInputValue(myMoney);form.setFieldsValue({extractMoney:myMoney})}}>{'全部提现'}</div>
          </div>
          :null
        }
      </div>
      <div style={{width: '100%',padding: '5vh',marginTop: '2vh'}}>
        <Button className="extractBtn" style={(!inputValue||parseFloat(inputValue)>parseFloat(myMoney)||parseFloat(inputValue)<0.3)?{opacity: 0.6}:{opacity: 1}} type="primary" danger onClick={()=>{wallet_withdraw()}}>{'提现'}</Button>
      </div>
      <Modal
        title={'绑定手机号'}
        visible={modalVisible}
        footer={null}
        onCancel={()=>{setModalVisible(false)}}
      >
        <div>
          <div className='content'>
            <div style={{marginRight: 10}}>{'手机号:'}</div>
            <InputNumber style={{flex:1}} onChange={(value: string)=>{setPhone(value)}}/>          
          </div>
          <div className='content' style={{marginTop:10}}>
            <div style={{marginRight: 10}}>{'验证码:'}</div>
            <Input style={{flex:1}} onChange={(e)=>{setCode_sms(e.target.value)}}/>
            <Button disabled ={phone&&!timeShow?false:true} onClick={()=>{
              if(phone){
                if(!/^1([358][0-9]|4[579]|66|7[0135678]|9[89])[0-9]{8}$/.test(phone)){
                  message.error({
                    content: '手机号格式不正确',
                    style: {
                      marginTop: '20vh',
                    },
                  });
                  setModalVisible(true);
                  return false;
                }
                send_sms()
                setTimeShow(true)
                const timer = setInterval(()=>{
                  setDaojishi((q)=>{
                    if(q<=1){
                      setTimeShow(false)
                      clearInterval(timer);
                      return 60
                    }
                    return q-1
                  })
                },1000)
              }
              }}>{timeShow?daojishi:'获取验证码'}</Button>
          </div>
          <Button style={{width:'100%',marginTop:10}} type={'primary'}
            onClick={async()=>{
              if(phone&&code_sms){
                await bind_mobile()
              }else{
                message.error({
                  content: '请填写手机号和验证码',
                  style: {
                    marginTop: '20vh',
                  },
                });
              }
            }}
          >{'提交'}</Button>
        </div>
      </Modal>
    </div>
  );
}
export default Extract;