import React, { useState, useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { message} from 'antd';
import Api from '../../api';
import './myWallet.css';

interface InitProps { }
type MainProps = InitProps & RouteComponentProps;
const MyWallet: React.FC<MainProps> = (props: MainProps) => {
  const [myMoney, setMyMoney] = useState<string>('');
  useEffect(() => {
    document.title = '钱包';
    if(!localStorage.getItem('wx_token')||!localStorage.getItem('isNew')){
      const getQueryVariable = (variable: any)=>{
        let query = window.location.search.substring(1);
        let vars = query.split("&");
        for (let i in vars) {
          let pair = vars[i].split("=");
          if(pair[0] == variable){return pair[1];}
        }
        return(false);
      }
      if (getQueryVariable('wx_token')){
        let a = getQueryVariable('wx_token') + '';
        localStorage.setItem('wx_token',a);
        localStorage.setItem('isNew','1');// 这个是强行判断是不是最新的wx_token的
        setTimeout(()=>{
          if(localStorage.getItem('wx_token')&&localStorage.getItem('isNew')){
            window.location.replace(Api.url2+'myWallet');  // 这里第一次登录时，wx.config会执行报错因为url冲突，不知道怎么改所以暂时改成跳转了
          }
        },1000)
      }else{
        getLoginData(); //  获取登录token
      }
    }else{
      getWallet();
    }
  }, []);
  const getLoginData = async () => {  // 获取用户登录信息
    return fetch(Api.url+'/wechat/official_url?url='+Api.url2+'myWallet', 
      {
        method: 'GET',
        headers: {
            'content-type': 'application/json',
        },
      })
      .then(response => response.json())
      .then(function(result) {
          if(result){
            if(result.code===0&&result.msg==='success'){
              window.location.replace(result.data);
              return true;
            }else{
              message.error({
                content: result.msg,
                style: {
                  marginTop: '20vh',
                },
              });
              return false;
            }
          }else{
            message.error({
              content: '获取用户信息失败，请重试',
              style: {
                marginTop: '20vh',
              },
            });
            return false;
          }
      })
  };
  const getWallet = async()=>{  // 获取钱包余额
    message.destroy();
    const hide = message.loading({
      content: '加载中请稍等',
      style: {
        marginTop: '20vh',
      },
    });
    return fetch('https://dmp.machmall.net/api/v1/user/wallet?wx_token='+ localStorage.getItem('wx_token')+'&appId='+Api.appId, 
      {
        method: 'GET',
        headers: {
            'content-type': 'application/json',
        },
      })
      .then(response => response.json())
      .then(function(result) {
        if(result){
          hide();
          if(result.code===0&&result.msg==='success'){
            setMyMoney(result.data);
            return true;
          }else{
            message.error({
              content: result.msg,
              style: {
                marginTop: '20vh',
              },
            });
            return false;
          }       
        }else{
          message.error({
            content: '获取钱包信息失败，请重试',
            style: {
              marginTop: '20vh',
            },
          });
        }
      })
  }
  return (
    <div style={{display:'flex',flexDirection: 'column'}}>
      <div className="myWalletTop" style={{backgroundImage: `url(myWalletBG.jpg)`,backgroundSize:'cover' }}>
        <div>{'账户余额（元）'}</div>
        <div className="topTxt"><span style={{fontSize: 24}}>￥</span><span>{myMoney}</span></div>
      </div>  
      <div className="myWalletBottom" onClick={()=>{props.history.push('/extract')}}>
        <img src="money1.png" alt="" width='30'/>
        <div style={{flex: 1,marginLeft: '0.5vh'}}>
          <div style={{fontWeight: 'bold'}}>{'提现'}</div>
          <div>{'成功提现到微信'}</div>
        </div>
        <div><img src="right.png" alt="" width="8"/></div>
      </div>
    </div>
  );
}
export default MyWallet;