import React,{ Component } from 'react'
class App2 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isVip:props.isVip,
            hasShop:props.hasShop,
        };
      }
    render(){
        return(
            <wx-open-launch-weapp class="launch-btn" username="gh_71a136efa86d" path={
                !this.state.hasShop?"pages/member/step/step.html"
                :!this.state.isVip?"pages/member/vip/vip.html"
                :''
            }
                style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
              >
              <script type="text/wxtag-template">
                <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', opacity: 0 }} />       
              </script> 
            </wx-open-launch-weapp>
        )
    }
}
export default App2