import React, { useState, useEffect } from 'react';
import { InputNumber, Button, Form , message} from 'antd';
import { RouteComponentProps } from 'react-router-dom';
import Api from '../../api';
import wx from 'weixin-js-sdk';
import './index.css';
interface InitProps { }
type MainProps = InitProps & RouteComponentProps;
const Contact_service: React.FC<MainProps> = (props: MainProps) => {
  useEffect(() => {
    document.title = '联系客服';
    getWXconfig();
  }, []);
  const getWXconfig = async () =>{  // 微信config的配置    
    return fetch(Api.url+'/wechat/sign?url='+encodeURIComponent(window.location.href.split('#')[0]+''), 
      {
        method: 'GET',
        headers: {
            'content-type': 'application/json',
        }
      })
    .then(response => response.json())
    .then(function(res) {
        if(res&&res.code === 0){
            let datas = res.data;
            wx.config(datas);
        }else{
            message.error({
                content: res.msg,
                style: {
                  marginTop: '20vh',
                },
            });
        }
    })
} 
  return (
    <div style={{background: '#fff',width: '100%', height: '100%',borderTop:'1px solid #f1f1f1'}}>
      <div className={'transformDiv'}>
	   
	   <a href='tel:400-887-5190' style={{background: '#cc351e',overflow:'hidden',borderRadius:'20px',maxWidth: '220px',
	  margin:'0rem auto 20px',display: 'flex', height: "36px",lineHeight:"36px", justifyContent: 'center'}}>
	      <img  src="tel.png" alt="" style={{width:'24px',height:'24px',marginTop: '6px', marginRight: '5px'}}/>
	      <span style={{color:'#fff', fontSize:'23px',}}>{'400-887-5190'}</span>
	    </a>
	    <img  src="kf.jpg" alt="" style={{margin:'10px auto 10px',width:'225px',height:'auto',display: 'block',}}/>
	    <div style={{fontSize:'16px', textAlign:"center",}}>长按二维码联系客服</div>
		
	  </div>
    </div>
  );
}
export default Contact_service;