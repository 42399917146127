import React,{ Component } from 'react'
class App4 extends Component {
    constructor(props) {
        super(props);
        this.state = {
          goods_id:props.goods_id,
          sku_id:props.sku_id,
        };
      }
    render(){
        return(
            <wx-open-launch-weapp class="launch-btn" username="gh_71a136efa86d" path={"pages/goods/detail/detail?goods_id="+this.state.goods_id+"&sku_id="+this.state.sku_id}
                style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
              >
              <script type="text/wxtag-template">
                <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', opacity: 0 }} />       
              </script> 
            </wx-open-launch-weapp>
        )
    }
}
export default App4