import React,{ Component } from 'react'
class ListContainer extends Component {
    render(){
        return(
            <wx-open-launch-weapp class="launch-btn" username="gh_71a136efa86d" path={"pages/goods/search/search?type=3"}
                style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
              >
              <script type="text/wxtag-template">
                <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', opacity: 0 }} />       
              </script> 
            </wx-open-launch-weapp>
        )
    }
}
export default ListContainer