import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { message } from 'antd';
import App4 from './App4';
import Api from '../../api';
import wx from 'weixin-js-sdk';
interface InitProps { }
type MainProps = InitProps & RouteComponentProps;
const JumpApplets: React.FC<MainProps> = (props: MainProps) => {
  const [goods_id, setGoods_id] = useState<string>('');
  const [sku_id, setSku_id] = useState<string>('');
    useEffect(() => {
        getWXconfig();
    }, []);
    const getWXconfig = async () =>{  // 微信config的配置    
        return fetch(Api.url+'/wechat/sign?url='+encodeURIComponent(window.location.href.split('#')[0]+''), 
          {
            method: 'GET',
            headers: {
                'content-type': 'application/json',
            }
          })
        .then(response => response.json())
        .then(function(res) {
            if(res&&res.code === 0){
                let datas = res.data;
                wx.config(datas);
                let goods_id:any = /(\d+)\&sku_id=(\d+)/.exec(window.location.href)
                if(goods_id[1]){
                    setGoods_id(goods_id[1])
                }
                if(goods_id[2]){
                    setSku_id(goods_id[2])
                }
            }else{
                message.error({
                    content: res.msg,
                    style: {
                      marginTop: '20vh',
                    },
                });
            }
        })
    } 
    return(
        <div style={{height:'100vh',position:'relative',display:'flex',alignItems:'center',justifyContent:'center',background:'#abcdef'}}>
            {
                goods_id&&sku_id?<App4 goods_id={goods_id} sku_id={sku_id}/>:null
            }
            <div>{'轻触屏幕跳转小程序'}</div>
        </div>
    )
}
export default JumpApplets;